<template>
  <div class="container">

    <div class="graduate-work-score mt-4 mb-4">
      <h4 class="text-center mb-4">Оценка дипломной работы</h4>

      <div v-if="graduateWorkScore_form.studentGroups.length != 0">

        <!--        <h4 class="text-center">{{graduateWorkScore_form.studentGroups[0].student_groups_name}}</h4>-->
        <!--        <div v-for="(groups, index) in graduateWorkScore_form.studentGroups" :key="index">-->


        <div class="col-md-4 m-3">
          <div>Фильтрация по группам</div>
          <select class="form-control form-select" id="filterGroups"
                  @change="changeFilterGroups">
            <option
                v-for="(item, index) in [{student_groups_id: 0, student_groups_name: 'Выберите группу'}, ...graduateWorkScore_form.studentGroups]"
                :value="item.student_groups_id"
                :key="index"
                :selected="item.student_groups_id == groupId">{{ item.student_groups_name }}

            </option>
          </select>
        </div>


        <div v-if="groupId">


          <DataTable :value="filteredStudents" showGridlines stripedRows responsiveLayout="scroll">

            <!--            <template #header>-->
            <!--              <div class="flex justify-content-center align-items-center">-->
            <!--                <h5 class="m-0">{{ graduateWorkScore_form.studentGroups[0].student_groups_name }}</h5>-->
            <!--              </div>-->
            <!--            </template>-->
            <Column header="Баркод">
              <template #body="{data}">

                <router-link :to="'/student-transcript?barcode=' + data.barcode" target="_blank">
                  {{ data.barcode }}
                </router-link>
              </template>
            </Column>
            <Column header="ФИО">
              <template #body="{data}">
                {{ data.last_name }} {{ data.first_name }}
              </template>
            </Column>
            <Column header="Название темы">
              <template #body="{data}">
                <p v-if="data.is_complex_exam === '1'">Комплексный экзамен</p>
                <a v-else href="#" @click="openStudentGraduateWorkFilesInfoDialog(data.student_data_id)">
                  {{ data.theme_name_ru }}
                </a>
              </template>
            </Column>
            <Column field="student_groups_name" header="Группа"></Column>
            <Column header="Финальная глава">
              <template #body="{data}">
                <div v-if="data.last_chapter == 1">
                  <p>Загружена</p>

                </div>
                <div v-else>
                  Не загружена
                </div>

              </template>
            </Column>
            <Column header="Оценка">
              <template #body="{data}">

                <div v-if="data?.graduate_work_score_id == null && !isGraduateWorkTechSecretary">
                  <Button label="Оценка" @click="openAddScoreDialog(data.student_data_id)"
                          style="margin-bottom: 5px"/>
                  <!--                    && ['admin', 'graduateWorkChairman', 'graduateWorkCommissionMember'].some(this.checkRole)-->
                </div>
                <!--                  :disabled="data.last_chapter == 0"-->
                <div v-else>
                  <b>{{data.graduate_work_score}}</b>
                  <!--                  <input class="form-control" type="number" :value="data.graduate_work_score" disabled>-->
                </div>


              </template>
            </Column>

              <Column header="Справка">
                  <template #body="{data}">
<!--                      <Button severity="warning" icon="pi pi-file-pdf" @click="openAddScoreDialog(data.student_data_id)"/>-->
                      <a :href="data.plagiat_url"><Button :disabled="!data.plagiat_url">Плагиаризм</Button></a>
                  </template>
              </Column>

            <Column header="Дата выдачи">
              <template #body="{data}">
                <p v-if="data.date">{{data.date}}</p>
                <p v-else>Не выдана</p>
              </template>
            </Column>


            <Column v-if="isGraduateWorkTechSecretary" header="Номер протокола">
              <template #body="{data}">
                <input class="form-control" type="number" min="0" placeholder="Номер протокола"
                       :disabled="data.id&&!data.edit_status"
                       v-model="data.protocol_number">
              </template>
            </Column>
            <Column v-if="isGraduateWorkTechSecretary" header="Дата протокола">
              <template #body="{data}">
                <input class="form-control" type="date" placeholder="Дата протокола"
                       :disabled="data.id&&!data.edit_status"
                       v-model="data.protocol_date">
              </template>
            </Column>
            <Column v-if="isGraduateWorkTechSecretary" header="Действие">
              <template #body="{data}">
                <div class="text-center">
                  <div v-if="data?.id">
                    <Button v-if="data?.edit_status" icon="pi pi-check" class="p-button-rounded"
                            :loading="btnSaveStatus"
                            @click="putGraduateData(data.student_id)"/>
                    <Button v-else icon="pi pi-pencil" class="p-button-rounded p-button-warning"
                            @click="data.edit_status = true"/>
                  </div>
                  <div v-else>
                    <Button icon="pi pi-save" class="p-button-rounded"
                            :loading="btnSaveStatus"
                            @click="postGraduateData(data.student_id)"/>
                  </div>
                </div>
              </template>
            </Column>

          </DataTable>
        </div>


        <!--      <div class="my-4 text-center">-->
        <!--        <button type="button" class="btn btn-primary" @click="saveScore" :disabled="btnSaveStatus">-->
        <!--          <span v-if="btnSaveStatus" class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>-->
        <!--          {{btnSaveStatus ? 'Сохранение' : 'Сохранить'}}-->
        <!--        </button>-->
        <!--      </div>-->

      </div>

    </div>


    <Dialog v-model:visible="addScoreDisplay" :style="{width: '50vw'}" :closable="false">
      <template #header>
        <h5>Поставить оценку</h5>
      </template>

      <div style="height: 400px" class="col-md-12 mt-4">

        <h5>{{studentName}}</h5>

        <div class="row mt-5">
          <div class="col-md-3">
            <p v-if="graduateWorkScore_form.students.find(i => i.student_data_id === student_id).is_complex_exam === '1'">Предмет 1</p>
            <p v-else>Ценность исследования</p>
          </div>
          <div class="col-md-9">
            <input v-if="graduateWorkScore_form.students.find(i => i.student_data_id === student_id).is_complex_exam === '1'"
                   class="form-control" type="number" placeholder="Оценка"
                   @change="changeResearchValueScore($event)">
            <input v-else class="form-control" type="number" placeholder="Оценка"
                   v-tooltip.bottom="'актуальность, научная новизна, практическое значение, обоснованность выводов и результатов'"
                   @change="changeResearchValueScore($event)">
          </div>
        </div>

        <div class="row mt-4">
          <div class="col-md-3">
            <p v-if="graduateWorkScore_form.students.find(i => i.student_data_id === student_id).is_complex_exam === '1'">Предмет 2</p>
            <p v-else>Содержание и оформление работы</p>
          </div>
          <div class="col-md-9">
            <input v-if="graduateWorkScore_form.students.find(i => i.student_data_id === student_id).is_complex_exam === '1'"
                   class="form-control" type="number" placeholder="Оценка"
                   @change="changeContentAndDesignScore($event)">
            <input v-else class="form-control" type="number" placeholder="Оценка"
                   v-tooltip.bottom="'количество и качество использованных источников, общая грамотность, логичность и ' +
                      'последовательность изложения материала, точность и достоверность расчетов и результатов, раскрытие темы, техническое оформление работы'"
                   @change="changeContentAndDesignScore($event)">
          </div>
        </div>

        <div class="row mt-4">
          <div class="col-md-3">
            <p v-if="graduateWorkScore_form.students.find(i => i.student_data_id === student_id).is_complex_exam === '1'">Предмет 3</p>
            <p v-else>Общее впечатление от выпускной работы обучающегося</p>
          </div>
          <div class="col-md-9">
              <input v-if="graduateWorkScore_form.students.find(i => i.student_data_id === student_id).is_complex_exam === '1'"
                   class="form-control" type="number" placeholder="Оценка"
                   @change="changeGeneralImpressionScore($event)">
              <input v-else class="form-control" type="number" placeholder="Оценка"
                   v-tooltip.bottom="'Самостоятельность выполнения, знания обучающегося и владение материалом, выступление и презентация, ' +
                      'внешний вид, достижения прошлых лет, отзыв научного руководителя и оценка рецензента'"
                   @change="changeGeneralImpressionScore($event)">
          </div>
        </div>

      </div>

      <template #footer>
        <Button label="Закрыть" icon="pi pi-times" class="p-button-text" @click="closeAddScoreDialog"/>
        <!--          <Button label="Сохранить" icon="pi pi-check" @click="saveScore"/>-->
        <button type="button" class="btn btn-primary" @click="saveScore"
                :disabled="btnSaveStatus">
          <span v-if="btnSaveStatus" class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
          {{ btnSaveStatus ? 'Сохраняется' : 'Сохранить' }}
        </button>
      </template>
    </Dialog>


    <Dialog v-model:visible="studentGraduateWorkFilesInfoDisplay" :style="{width: '50vw'}" :closable="false">
      <template #header>
        <h5>Файлы дипломной работы</h5>
      </template>

      <div style="height: 400px" class="col-md-12 mt-4">

        <div class="row mt-4" v-if="graduateWorkScore_form.studentGraduateWorkFiles.length != 0">


          <ul v-for="(item, index) in graduateWorkScore_form.studentGraduateWorkFiles" :key="index">

            <li>{{item.chapter_name}} - <a :href="'https://back.uib.kz/uploads/' + item.file_url" class="mx-1">Файл</a>
              - {{convertGraduateWorkTimestampToDate(item.created_at)}}
            </li>

          </ul>
          <!--            <div class="col-md-3">-->
          <!--              <p>Предыдущий документ об образовании (на казахском)</p>-->
          <!--            </div>-->
          <!--            <div class="col-md-9">-->
          <!--              <input id="info_doc_kz" type="text" class="form-control"-->
          <!--                     placeholder="Предыдущий документ об образовании (на казахском)"-->
          <!--                     :value="studentsList_form.studentsListInfos.find(i=>i.id == infoStudentId).studentPreviousEducationDocument.doc_kz" disabled>-->
          <!--            </div>-->
        </div>
        <div v-else>
          <p>Файлов нет</p>
        </div>


      </div>

      <template #footer>
        <Button label="Закрыть" icon="pi pi-times" class="p-button-text"
                @click="closeStudentGraduateWorkFilesInfoDialog"/>
      </template>
    </Dialog>


  </div>


</template>


<script>

  import {mapActions, mapState} from "vuex";
  import {convertGraduateWorkTimestampToDate} from "@/utils/helpers/convertGraduateWorkTimestampToDate"
  import httpClient from '@/services/http.service'
  import {decryptCheckRole} from "@/utils/helpers/decryptedRoles.helpers";

  export default {
    name: "GraduateWorkScore",
    data() {
      return {
        graduateWorkScoreData: [],
        btnSaveStatus: false,
        addScoreDisplay: false,
        research_value_score: null,
        content_and_design_score: null,
        general_impression_score: null,
        student_id: null,
        studentGraduateWorkFilesInfoDisplay: false,
        groupId: null,
        studentName: '',
        btnSaveGraduateDataStatus: false,
      }
    },
    computed: {
      ...mapState('graduateWorkScore', ['graduateWorkScore_form']),
      filteredStudents() {
        return this.graduateWorkScore_form.students.filter(i => i.student_groups_id === this.groupId)
      },
      isGraduateWorkTechSecretary() {
        return decryptCheckRole('graduateWorkTehSecretary')
      }
    },
    methods: {
      ...mapActions('graduateWorkScore', ['GET_STUDENTS_BY_GROUP_ID', 'POST_GRADUATE_WORK_SCORE_STUDENTS', 'GET_STUDENT_GRADUATE_WORK_FILES']),
      convertGraduateWorkTimestampToDate,
      async changeFilterGroups(e) {
        this.groupId = e.target.value
      },

      openAddScoreDialog(student_id) {
        this.student_id = student_id
        const student = this.graduateWorkScore_form.students.find(i => i.student_data_id === student_id)

        console.log(student)
        if (student) {
          this.studentName = student?.last_name + ' ' + student?.first_name
        }

        this.addScoreDisplay = true
      },

      closeAddScoreDialog() {
        this.addScoreDisplay = false
        this.student_id = null
        this.score = null
      },

      openStudentGraduateWorkFilesInfoDialog(student_id) {
        this.studentGraduateWorkFilesInfoDisplay = true
        this.student_id = student_id
        this.GET_STUDENT_GRADUATE_WORK_FILES(student_id)
      },
      closeStudentGraduateWorkFilesInfoDialog() {
        this.studentGraduateWorkFilesInfoDisplay = false
        this.student_id = null
      },
      changeResearchValueScore(e) {
        //console.log(student_id, 'student_id')
        this.research_value_score = e.target.value
        console.log(this.research_value_score, 'research_value_score')

        // if (!this.graduateWorkScoreData.find(i=>i.student_id == student_id)){
        //   this.graduateWorkScoreData.push({
        //     student_id: student_id,
        //     score: score,
        //   })
        // }
        // else {
        //   this.graduateWorkScoreData.find(i=>i.student_id == student_id).score = score
        // }
        // console.log(this.graduateWorkScoreData, 'this.graduateWorkScoreData')

      },

      changeContentAndDesignScore(e) {

        this.content_and_design_score = e.target.value
        console.log(this.content_and_design_score, 'content_and_design_score')

      },

      changeGeneralImpressionScore(e) {

        this.general_impression_score = e.target.value
        console.log(this.general_impression_score, 'general_impression_score')

      },

      async saveScore() {
        this.btnSaveStatus = true


        this.graduateWorkScoreData.push({
          student_id: this.student_id,
          research_value_score: this.research_value_score,
          content_and_design_score: this.content_and_design_score,
          general_impression_score: this.general_impression_score,

        })

        console.log(this.graduateWorkScoreData, 'graduateWorkScoreData')
        let res = await this.POST_GRADUATE_WORK_SCORE_STUDENTS(this.graduateWorkScoreData)


        if (res) {

          await this.GET_STUDENTS_BY_GROUP_ID()
          this.$message({text: 'Сохранено'})
          this.graduateWorkScoreData = []
          this.research_value_score = null
          this.content_and_design_score = null
          this.general_impression_score = null
          this.student_id = null
          this.addScoreDisplay = false
          this.btnSaveStatus = false
        }
        //this.btnSaveStatus = false
      },

      async postGraduateData(student_id) {
        this.btnSaveGraduateDataStatus = true
        const graduateDataIndex = this.graduateWorkScore_form.students.findIndex(i => i.student_id == student_id)

        if (graduateDataIndex >= 0) {
          const graduateData = this.graduateWorkScore_form.students[graduateDataIndex]
          const postData = {
            protocol_date: graduateData.protocol_date,
            protocol_number: graduateData.protocol_number,
            student_id: graduateData.student_id
          }

          try {
            const {status} = await httpClient.post(`student_data/student-graduate-data/create`, postData)
            if (status === 200) {
              await this.GET_STUDENTS_BY_GROUP_ID()
              this.$message({text: 'Сохранено'})
            }
          } catch (e) {
            this.showError(e)
          }
        }

        this.btnSaveGraduateDataStatus = false
      },
      async putGraduateData(student_id) {
        this.btnSaveGraduateDataStatus = true
        const graduateDataIndex = this.graduateWorkScore_form.students.findIndex(i => i.student_id == student_id)

        if (graduateDataIndex >= 0) {
          const graduateData = this.graduateWorkScore_form.students[graduateDataIndex]

          const putData = {
            id: graduateData.id,
            protocol_date: graduateData.protocol_date,
            protocol_number: graduateData.protocol_number,
            student_id: graduateData.student_id
          }

          try {
            const {status} = await httpClient.put(`student_data/student-graduate-data/update?id=${putData.id}`, putData)
            if (status === 200) {
              await this.GET_STUDENTS_BY_GROUP_ID()
              this.$message({text: 'Изменено'})
            }
          } catch (e) {
            this.showError(e)
          }
        }

        this.btnSaveGraduateDataStatus = false
      },
      showError(e) {
        this.$error({text: `Произошла ошибка ${JSON.stringify(e.response?.data?.errors)}`})
      },

    },

    async mounted() {
      await this.GET_STUDENTS_BY_GROUP_ID()
    }
  }
</script>

<style scoped>

  #research_value_score_info:hover + .research-value-score-info {
    display: block;
    position: absolute;
    top: 35%; /* Абсолютное позиционирование */
    width: 80%;
    z-index: 15;

  }

  .research-value-score-info {
    display: none;
  }
</style>
